import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PublishDate = makeShortcode("PublishDate");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A model of the evolution of the Indian Ocean that has been under development for
many years has  now been published. The digital version was released on February
27 under doi number `}<a parentName="p" {...{
        "href": "http://doi.org/10.1144/SP431.12"
      }}>{`http://doi.org/10.1144/SP431.12`}</a>{` with the title `}<strong parentName="p">{`Insight into
the Eastern Margin of Africa from a New Tectonic Model of the Indian Ocean.`}</strong>{` The
contribution is part of Geological Society  Special Publication No. 431:
`}<em parentName="p">{`Transform Margins: Development, Controls and Petroleum Systems`}</em>{` (eds Nemčok, M.,
Rybár, S., Sinha, S.T., Hermeston, S.A., & Ledvényiová, L.). `}</p>
    <p>{`The paper addresses (a) a Gondwana reassembly refining earlier publications and
(b) a dispersal  model based largely on ocean fracture zones, focusing on the
relative motions of Antarctica  against Africa and India against Antarctica. A
new examination of the movements of Madagascar  against Africa then leads to a
closure of the plate circuit between Madagascar and India with the  least
invention of unsubstantiated movements between these two fragments and the
intervening  Seychelles and the Mascarene fragments. An animation of the model
is to be found under the  'Gondwana' tab at the top of this webpage. The Euler
rotation parameters that define the model  are published in this paper for the
first time. A rotation file for use with CPSL 'Atlas' software is  available
from the author on request. `}</p>
    <PublishDate mdxType="PublishDate" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      